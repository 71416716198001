import { render, staticRenderFns } from "./OffersOrders.vue?vue&type=template&id=323fe1a1&scoped=true&"
import script from "./OffersOrders.vue?vue&type=script&lang=js&"
export * from "./OffersOrders.vue?vue&type=script&lang=js&"
import style0 from "./OffersOrders.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./OffersOrders.vue?vue&type=style&index=1&id=323fe1a1&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../Users/kcger/AppData/Roaming/npm/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323fe1a1",
  null
  
)

export default component.exports